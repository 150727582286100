@import 'variables.scss';
@import 'mixins.scss';

/*===============================================================
# Dashboard
===============================================================*/
.dashboard {
    display: flex;
    justify-content: flex-end;
}

.dashboard-app {
    width: 75%;
    padding: 2%;

    h3 {
        text-align: center;
        font-size: 2.5vw;
        text-transform: capitalize;
        font-weight: bold;
        color: $orange;
    }
}

.welcome {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-20%, -50%);
    text-align: center;

    h2 {
        font-weight: 800;
        text-shadow: 0px 0px 0px $orange;
        color: $orange;
        font-size: 8vw;
        text-transform: uppercase;
    }

    p {
        font-size: 3vw;
    }
}

/*===============================================================
# Login
===============================================================*/
.admin-login {
    $color_1: #7875B5;
    $color_2: #4C489D;
    $color_3: #fff;
    $border-bottom-color_1: $orange;


    .container {
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 100vh;
        padding: 5% 0;
    }

    .screen {
        // background: linear-gradient(90deg, #5D54A4, #7C78B8);
        background: linear-gradient(90deg, #fab232, #eae5d6);
        position: relative;
        height: 500px;
        width: 360px;
        box-shadow: 0px 0px 24px $cream;
        margin: auto;
    }

    .screen__content {
        z-index: 1;
        position: relative;
        height: 100%;
    }

    .screen__background {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 0;
        -webkit-clip-path: inset(0 0 0 0);
        clip-path: inset(0 0 0 0);
    }

    .screen__background__shape {
        transform: rotate(45deg);
        position: absolute;
    }

    .screen__background__shape1 {
        height: 520px;
        width: 520px;
        background: #FFF;
        top: -50px;
        right: 120px;
        border-radius: 0 72px 0 0;
    }

    .screen__background__shape2 {
        height: 220px;
        width: 220px;
        background: $orange;
        top: -172px;
        right: 0;
        border-radius: 32px;
    }

    .screen__background__shape3 {
        height: 540px;
        width: 190px;
        background: $gradient;
        top: -24px;
        right: 0;
        border-radius: 32px;
    }


    .login {
        width: 100%;
        padding: 30px;
        padding-top: 20%;

        img {
            width: 50%;
            padding-bottom: 10%;
        }
    }

    .login__field {
        padding: 20px 0px;
        position: relative;

        svg {
            position: absolute;
            top: 30px;
            width: 20px;
            height: 20px;
            fill: $orange;
        }
    }



    .login__input {
        border: none;
        border-bottom: 2px solid $cream;
        background: none;
        padding: 10px;
        padding-left: 10%;
        font-weight: 500;
        width: 75%;
        transition: .2s;

        &:active {
            outline: none;
            border-bottom-color: $border-bottom-color_1;
        }

        &:focus {
            outline: none;
            border-bottom-color: $border-bottom-color_1;
        }

        &:hover {
            outline: none;
            border-bottom-color: $border-bottom-color_1;
        }
    }

    .login__submit {
        background: #fff;
        font-size: 14px;
        margin-top: 30px;
        padding: 16px 20px;
        border-radius: 26px;
        border: 1px solid $yellow;
        text-transform: uppercase;
        font-weight: 700;
        display: flex;
        align-items: center;
        width: 100%;
        color: $orange;
        box-shadow: 0px 2px 2px $orange;
        cursor: pointer;
        transition: .2s;

        svg {
            width: 20px;
            height: 20px;
            margin-left: auto;
            fill: $light-orange;
        }

        &:active {
            border-color: $orange;
            outline: none;
        }

        &:focus {
            border-color: $orange;
            outline: none;
        }

        &:hover {
            border-color: $orange;
            outline: none;
            background: $orange;
            box-shadow: 0px 2px 2px rgba($color: #000, $alpha: 0.3);
            color: #fff;
            transition: $transition;
            border: 1px solid #fff;

            svg {
                fill: #fff;
            }

        }
    }

    .button__icon {
        font-size: 24px;
        margin-left: auto;
        color: $color_1;
    }

    .social-login {
        position: absolute;
        height: 140px;
        width: 160px;
        text-align: center;
        bottom: 0px;
        right: 0px;
        color: $color_3;
    }

    .social-icons {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .social-login__icon {
        padding: 20px 10px;
        color: $color_3;
        text-decoration: none;
        text-shadow: 0px 0px 8px #7875B5;

        &:hover {
            transform: scale(1.5);
        }
    }
}



/*===============================================================
# Admin Dashbaord
===============================================================*/

.dashboard-nav {
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    width: 25%;
    background: $gradient;
    color: #ffff;
    padding: 3%;

    .menu {
        fill: #fff;
        width: 30px;
        height: 30px;
        margin-left: auto;
        margin-top: 3%;
        display: none;
    }

    .a-logo {
        background: #fff;
        display: block;
        margin-bottom: 5%;
        padding: 8%;
        border-radius: 5px;

        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }

    .dashboard-nav-list,
    .dashboard-nav-mobile-list {
        @include flex;
        flex-direction: column;
        margin-top: 20%;

        .dashboard-nav-item {
            margin-bottom: 5%;
            font-size: 1.5vw;
            text-transform: capitalize;
            padding: 3%;
            display: flex;
            align-items: center;
            justify-content: flex-start;

            svg {
                width: 15px;
                height: 15px;
                margin-right: 5%;
                fill: #fff;
            }
        }

        button {
            width: 90%;
            margin: 0 5%;
            border: none;
            border-top: 1px solid #fff;
            padding: 5%;
            position: absolute;
            bottom: 0;
            right: 0;
            background: transparent;
            display: flex;
            align-items: center;
            justify-content: center;

            p {
                margin-bottom: 0;
                font-size: 1.7vw;
                color: #fff;

            }

            svg {
                width: 15px;
                height: 15px;
                margin-left: 5%;
                fill: #fff;
            }
        }
    }

    .dashboard-nav-mobile-list {
        display: none;
    }

    .admin-blog {
        margin-bottom: 5%;
        font-size: 1.5vw;
        text-transform: capitalize;
        padding: 3%;

        svg {
            width: 15px;
            height: 15px;
            margin-right: 5%;
            fill: #fff;
        }

        &:hover {
            transition: $transition;

            .admin-blog-nav {
                display: flex;
            }
        }

        .admin-blog-nav {
            @include flex;
            display: none;
            flex-direction: column;
            color: #fff;
            margin-top: 5%;
            padding-top: 5%;
            padding-left: 5%;
            border-top: 1px solid #fff;
            height: 100%;

            a {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                padding: 2%;
                margin: 0;
                font-size: 1.2vw;

                &:hover {
                    transform: scale(1.1) translateX(15px);
                    transition: .5s all ease;
                    background: transparent;
                }
            }


        }
    }
}

/*===============================================================
# Blog- Create
===============================================================*/
.blog-create {

    form {
        display: flex;
        flex-direction: column;
        margin: 5% 0;
        // box-shadow: 2px 2px 5px rgba($color: #000000, $alpha: 0.2);
        padding: 2%;

        .__input {
            display: flex;
            justify-content: space-between;

            label {
                font-size: 1.2vw;
                text-transform: capitalize;
                width: 30%;
            }

            .b__input {
                width: 100%;

                span {
                    font-size: 1vw;
                    color: red;
                }
            }
        }

        input,
        textarea {
            font-size: 1vw;
            border: none;
            padding: 1% 2%;
            border-radius: 5px;
            border: 1px solid rgba($color: #000000, $alpha: 0.2);
            outline: none;
            color: #000;
            width: 100%;
            margin-bottom: 2%;
            box-shadow: inset 0 1px 2px rgba($color: #000, $alpha: 0.3);
        }

        button {
            background: $gradient;
            color: #fff;
            border: none;
            font-size: 1.1vw;
            padding: 1%;
            border-radius: 5px;
            margin-top: 10%;
            width: 100%;
            box-shadow: 0 1px 2px rgba($color: #000, $alpha: 0.3);
        }
    }


}

/*===============================================================
# Blog- List
===============================================================*/
.blog-list {
    display: flex;
    align-items: stretch;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin: 5% 0;

    .blog-card {
        width: calc(100% / 3.2);
        box-shadow: 2px 2px 5px rgba($color: #000000, $alpha: 0.2);
        margin: 1%;

        position: relative;

        img {
            width: 100%;
        }


        .blog-card-text {
            margin-bottom: 20%;
            padding: 5%;

            p {
                font-size: 1.1vw;
                font-weight: bold;
                margin-bottom: 1%;
            }

            span {
                font-size: .9vw;
                color: $orange;

                svg {
                    width: 10px;
                    height: 10px;
                    fill: $orange;
                    margin-right: 5%;
                }
            }
        }

        .blog-card-button {
            display: flex;
            justify-content: center;
            align-items: stretch;
            position: absolute;
            bottom: 5%;
            left: 0;
            width: 100%;

            a,
            button {
                width: calc(100% / 2.2);
                border: none;
                padding: 2%;
                border-radius: 5px;
                font-size: 1vw;
                text-transform: capitalize;
                text-align: center;
                margin: 1%;
                background: $gradient;
                color: #fff;

                &:hover {
                    background: transparent;
                    color: #000;
                    transition: .2s all ease-in-out;
                    border: 1px solid $orange;
                }
            }
        }
    }

    p {
        font-size: 1.2vw;
    }
}

.brand-list {
    .blog-card {
        border: 1px solid $orange;
        border-radius: 10px;

        img {
            padding: 5% 20% 15% 20%;
        }
    }
}

/*===============================================================
# Contact DB
===============================================================*/
.contact-form {
    table {
        width: 100%;
        border-collapse: collapse;
        margin: 50px auto;
    }

    /* Zebra striping */
    tr:nth-of-type(odd) {
        background: #eee;
    }

    th {
        background: $orange;
        color: white;
        font-weight: bold;
    }

    td,
    th {
        padding: 10px;
        border: 1px solid #ccc;
        text-align: left;
        font-size: 1.1vw;
    }

    td {
        background: #fff;
    }
}

/*===============================================================
# Reset Password
===============================================================*/
.admin-settings {
    display: flex;
    flex-direction: column;
    margin: 10% auto 0 auto;
    width: 80%;


    input {
        font-size: 1.2vw;
        border: none;
        padding: 1% 2%;
        border-radius: 5px;
        border: 1px solid rgba($color: #000000, $alpha: 0.2);
        outline: none;
        color: #000;
        width: 100%;
        box-shadow: inset 0 1px 2px rgba($color: #000, $alpha: 0.3);
        margin-bottom: 1%;
    }

    button {
        background: $gradient;
        color: #fff;
        border: none;
        font-size: 1.3vw;
        padding: 1%;
        border-radius: 5px;
        margin-top: 1%;
        width: 100%;
        box-shadow: 0 1px 2px rgba($color: #000, $alpha: 0.3);
    }
}

/*===============================================================
# Media Queries
===============================================================*/
$xs: 320px;
$sm: 480px;
$md: 767px;
$lg: 1024px;
$xl: 1920px;

@media (min-width: $xs) and (max-width: $sm) and (orientation: portrait) {
    .welcome {
        left: 27%;

        h2 {
            font-size: 16vw;
        }

        p {
            font-size: 4vw;
        }
    }


    .dashboard {
        flex-direction: column;
    }

    .dashboard-nav {
        height: 15vh;
        width: 100%;
        display: flex;
        position: fixed;
        top: 0;
        left: 0;
        box-shadow: $box-shadow;
        z-index: 69999;

        .menu {
            display: block;
        }

        .a-logo {
            padding: 2% 3%;
            width: 40%;
            height: 100%;
        }

        .dashboard-nav-list {
            display: none;
        }

        .dashboard-nav-mobile-list {
            display: block;
            margin: 0;
            width: 50%;
            height: 100%;
            padding: 0 5%;
            position: fixed;
            right: 0;
            top: 0;
            background: #fff;
            z-index: 69999;
            box-shadow: $box-shadow;

            .close {
                fill: $orange;
                margin-left: auto;
                position: absolute;
                right: 5%;
                top: 2%;
                display: block;
                width: 30px;
                height: 30px;

            }

            .dashboard-nav-item {
                font-size: 4vw;
                color: $orange;

                svg {
                    fill: $orange;
                }
            }

            button {
                border-top: 1px solid $orange;

                p {
                    color: $orange;
                    font-size: 4vw;
                }

                svg {
                    fill: $orange;

                }
            }
        }

        .admin-blog {
            color: $orange;
            font-size: 4vw;
            margin-top: 50%;
            display: block;

            svg {
                fill: $orange;
            }

            .admin-blog-nav {
                display: block;
                color: $orange;
                height: 15%;
                border-top: 1px solid $orange;

                a {
                    font-size: 3.5vw;

                }
            }
        }


    }

    .dashboard-app {
        width: 100%;
        margin-top: 2%;

        h3 {
            font-size: 5vw;
            font-weight: bold;
            color: $orange;
        }
    }

    .blog-list {
        .blog-card {
            width: calc(100% / 2.1);

            .blog-card-text {
                p {
                    font-size: 3vw;
                }

                span {
                    font-size: 2.5vw;
                }
            }

            .blog-card-button {

                a,
                button {
                    font-size: 2.5vw;
                }
            }
        }
    }

    .blog-create form {
        .__input {
            label {
                font-size: 3vw;
                width: 45%;
            }

            .b__input {
                span {
                    font-size: 1.8vw;
                }
            }
        }

        input {
            font-size: 2.5vw;
        }

        button {
            font-size: 3vw;
        }
    }

    .ql-container.ql-snow {
        height: 70%;
    }

    .ql-snow.ql-toolbar button,
    .ql-snow .ql-toolbar button {
        height: 20px;
        width: 22px;
        margin-top: 0;
    }

    .admin-settings {

        input,
        button {
            font-size: 3vw;
        }
    }

    .contact-form {
        table {
            margin: 5% 0;

            thead {
                tr {
                    th {
                        font-size: 3vw;
                    }
                }
            }

            tbody {
                tr {
                    td {
                        font-size: 2.5vw;
                    }
                }
            }
        }
    }
}

@media (min-width: 481px) and (max-width: $md) and (orientation: landscape) {
    .welcome {
        left: 27%;

        h2 {
            font-size: 16vw;
        }

        p {
            font-size: 4vw;
        }
    }

    .dashboard {
        flex-direction: column;
    }

    .dashboard-nav {
        height: 15vh;
        width: 100%;
        display: flex;
        position: fixed;
        top: 0;
        left: 0;
        box-shadow: $box-shadow;
        z-index: 69999;

        .menu {
            display: block;
            margin-top: 1%;
        }

        .a-logo {
            padding: 4%;
            width: 20%;
            height: 100%;
            position: relative;
            margin: 0;

            img {
                width: 100px;
                height: 100px;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }

        .dashboard-nav-list {
            display: none;
        }

        .dashboard-nav-mobile-list {
            display: block;
            margin: 0;
            width: 50%;
            height: 100%;
            padding: 0 5%;
            position: fixed;
            right: 0;
            top: 0;
            background: #fff;
            z-index: 69999;
            box-shadow: $box-shadow;

            .close {
                fill: $orange;
                margin-left: auto;
                position: absolute;
                right: 5%;
                top: 2%;
                display: block;
                width: 30px;
                height: 30px;

            }

            .dashboard-nav-item {
                font-size: 3vw;
                color: $orange;

                svg {
                    fill: $orange;
                }
            }

            button {
                border-top: 1px solid $orange;

                p {
                    color: $orange;
                    font-size: 3vw;
                }

                svg {
                    fill: $orange;

                }
            }
        }

        .admin-blog {
            color: $orange;
            font-size: 3vw;
            margin-top: 30%;
            display: block;

            svg {
                fill: $orange;
            }

            .admin-blog-nav {
                display: block;
                color: $orange;
                height: 15%;
                border-top: 1px solid $orange;

                a {
                    font-size: 2.5vw;

                }
            }
        }


    }

    .dashboard-app {
        width: 100%;
        margin-top: 15%;

        h3 {
            font-size: 5vw;
            font-weight: bold;
            color: $orange;
        }
    }

    .blog-list {
        .blog-card {
            width: calc(100% / 2.1);

            .blog-card-text {
                p {
                    font-size: 2.5vw;
                }

                span {
                    font-size: 2vw;
                }
            }

            .blog-card-button {

                a,
                button {
                    font-size: 2vw;
                }
            }
        }
    }

    .blog-create form {
        .__input {
            label {
                font-size: 2.5vw;
                width: 45%;
            }

            .b__input {
                span {
                    font-size: 1.5vw;
                }
            }
        }

        input {
            font-size: 2vw;
        }

        button {
            font-size: 2.5vw;
        }
    }

    .ql-container.ql-snow {
        height: 70%;
    }

    .ql-snow.ql-toolbar button,
    .ql-snow .ql-toolbar button {
        height: 20px;
        width: 22px;
        margin-top: 0;
    }

    .admin-settings {

        input,
        button {
            font-size: 2.5vw;
        }
    }

    .contact-form {
        table {
            margin: 5% 0;

            thead {
                tr {
                    th {
                        font-size: 2.5vw;
                    }
                }
            }

            tbody {
                tr {
                    td {
                        font-size: 2vw;
                    }
                }
            }
        }
    }
}

@media (min-width: 481px) and (max-width: $md) and (orientation: portrait) {
    .welcome {
        left: 27%;

        h2 {
            font-size: 16vw;
        }

        p {
            font-size: 4vw;
        }
    }

    .dashboard {
        flex-direction: column;
    }

    .dashboard-nav {
        height: 15vh;
        width: 100%;
        display: flex;
        position: fixed;
        top: 0;
        left: 0;
        box-shadow: $box-shadow;
        z-index: 69999;

        .menu {
            display: block;
        }

        .a-logo {
            padding: 2% 3%;
            width: 40%;
            height: 100%;
        }

        .dashboard-nav-list {
            display: none;
        }

        .dashboard-nav-mobile-list {
            display: block;
            margin: 0;
            width: 50%;
            height: 100%;
            padding: 0 5%;
            position: fixed;
            right: 0;
            top: 0;
            background: #fff;
            z-index: 69999;
            box-shadow: $box-shadow;

            .close {
                fill: $orange;
                margin-left: auto;
                position: absolute;
                right: 5%;
                top: 2%;
                display: block;
                width: 30px;
                height: 30px;

            }

            .dashboard-nav-item {
                font-size: 4vw;
                color: $orange;

                svg {
                    fill: $orange;
                }
            }

            button {
                border-top: 1px solid $orange;

                p {
                    color: $orange;
                    font-size: 4vw;
                }

                svg {
                    fill: $orange;

                }
            }
        }

        .admin-blog {
            color: $orange;
            font-size: 4vw;
            margin-top: 50%;
            display: block;

            svg {
                fill: $orange;
            }

            .admin-blog-nav {
                display: block;
                color: $orange;
                height: 15%;
                border-top: 1px solid $orange;

                a {
                    font-size: 3.5vw;

                }
            }
        }


    }

    .dashboard-app {
        width: 100%;
        margin-top: 20%;

        h3 {
            font-size: 5vw;
            font-weight: bold;
            color: $orange;
        }
    }

    .blog-list {
        .blog-card {
            width: calc(100% / 2.1);

            .blog-card-text {
                p {
                    font-size: 3vw;
                }

                span {
                    font-size: 2.5vw;
                }
            }

            .blog-card-button {

                a,
                button {
                    font-size: 2.5vw;
                }
            }
        }
    }

    .blog-create form {
        .__input {
            label {
                font-size: 3vw;
                width: 45%;
            }

            .b__input {
                span {
                    font-size: 1.8vw;
                }
            }
        }

        input {
            font-size: 2.5vw;
        }

        button {
            font-size: 3vw;
        }
    }

    .ql-container.ql-snow {
        height: 70%;
    }

    .ql-snow.ql-toolbar button,
    .ql-snow .ql-toolbar button {
        height: 20px;
        width: 22px;
        margin-top: 0;
    }

    .admin-settings {

        input,
        button {
            font-size: 3vw;
        }
    }

    .contact-form {
        table {
            margin: 5% 0;

            thead {
                tr {
                    th {
                        font-size: 3vw;
                    }
                }
            }

            tbody {
                tr {
                    td {
                        font-size: 2.5vw;
                    }
                }
            }
        }
    }
}