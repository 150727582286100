$cream: #eae5d6;
$yellow: #fab232;
$light-orange: #F99931;
$orange: #f58432;
$black: #343434;
$grey: #585757;


$gradient: linear-gradient(90deg, rgba(250, 178, 50, 1) 0%, rgba(249, 153, 49, 1) 50%, rgba(245, 132, 50, 1) 100%);
$transparent-gradient: linear-gradient(90deg, rgba(250, 178, 50, 1) 0%, rgba(249, 153, 49, .7) 50%, rgba(245, 132, 50, 1) 100%);
$header-gradient: radial-gradient(circle, rgba(250, 178, 50, 1) 0%, rgba(249, 153, 49, 1) 50%, rgba(245, 132, 50, 1) 100%);
$title-gradient: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(234,229,214,1) 69%, rgba(250,178,50,1) 100%);
$faded-gradient: linear-gradient(90deg, rgba(250, 178, 50, .7) 0%, rgba(249, 153, 49, .7) 50%, rgba(245, 132, 50, 1) 100%);


$box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
$text-shadow: 2px 2px 2px rgba(0,0,0,0.3);

$padding: 0 5% 5% 5%;

$transition: .5s all ease;