$xs: 320px;
$sm: 480px;
$md: 767px;
$lg: 1024px;
$xl: 1920px;

@import 'variables.scss';

@media (min-width: $xs) and (max-width: $sm) and (orientation: portrait) {

    .banner {
        margin-top: 10%;

        .banner-caption {
            svg {
                width: 40px;
                height: 40px;
            }

        }
    }

    .heading {
        svg {
            width: 30px;
            height: 30px;
        }

        h4 {
            font-size: 4vw;
        }
    }

    .slider {
        // margin-top:-2%;
        // padding: 0 3%;

        .carousel {
            height: 50vh;
            margin-top: 3%;
        }

        .carousel-inner {

            .carousel-item {
                position: relative;
                height:100%;
                visibility: visible;

                video {
                    object-fit: cover !important;
                    width: 95% !important;
                    height: 30vh !important;
                    margin-left: 2.5%!important;
                }

                img {
                    height: 30vh;
                }

                h5 {
                    height: 40vh !important;
                    top: 28% !important;
                    font-size: 4vw !important;

                    ul {
                        li {
                            border-radius: 1px solid #fff !important;
                            font-size: 2.5vw !important;
                        }
                    }
                }
            }
        }
    }

    .home {

        .about {
            flex-direction: column;
            margin-top: -5%;

            .about-img {
                width: 100%;
            }

            .about-text {
                width: 100%;
                margin-top: 5%;
                padding-left: 0;

                h2 {
                    font-size: 4vw;
                }

                p {
                    font-size: 3vw;
                }
            }
        }

        .brand-portfolio {
            .brand-portfolio-body {
                .brand-portfolio-card {
                    width: calc(100% / 2.1);

                    h5 {
                        font-size: 3vw;
                    }
                }
            }
        }

        .training {
            .training-body {
                flex-direction: column;

                .training-img {
                    width: 100%;

                    &::after {
                        display: none;
                    }
                }

                .training-text {
                    width: 100%;
                    padding-left: 0;
                    margin-top: 5%;

                    p {
                        font-size: 3vw;
                    }

                    a {
                        font-size: 3vw;
                    }
                }
            }
        }

        .life {
            .life-body {
                flex-direction: column-reverse;

                .life-img {
                    width: 100%;

                    &::after {
                        display: none;
                    }
                }

                .life-text {
                    width: 100%;
                    padding-left: 0;
                    margin-top: 5%;

                    p {
                        font-size: 3vw;
                    }

                    a {
                        font-size:3vw;
                    }
                }
            }
        }

        .brands {
            .carousel .control-prev.control-arrow:before {
                margin-left: -50%;
            }

            .carousel .control-prev.control-arrow:after {
                margin-right: -50%;
            }

            .brands-body {
                .carousel-body {
                    padding: 0 5%;
                    margin-bottom: 15%;
                }
            }
        }

        .home-blog {
            .blog-body {
                flex-direction: column;

                .blog-card {
                    width: calc(100%);
                    margin-bottom: 5%;

                    .blog-text {
                        a {
                            font-size: 4vw;
                        }

                        p,
                        .read {
                            font-size: 3vw;

                            svg {
                                width: 10px;
                                height: 10px;
                            }
                        }
                    }
                }
            }
        }
    }

    .navbar {
        .header {
            .header-logo {
                img {
                    width: 150%;
                }
            }

            .header-links {
                display: none;
            }

            .menu {
                display: block;
            }
        }
    }

    .home-navbar {
        display: none;

        .header {
            .menu {
                display: block;
            }
        }
    }

    .mobile {
        display: flex;
    }

    .about-us {
        .about {
            flex-direction: column;

            .about-img {
                width: 100%;
            }

            .about-text {
                width: 100%;
                padding-left: 0;
                margin-top: 5%;

                p {
                    font-size: 2.5vw;
                }
            }
        }

        .achievements {
            padding: 2%;

            .a-card {
                svg {
                    width: 50px;
                    height: 50px;
                }

                h4 {
                    font-size: 3vw;
                }

                p {
                    font-size: 2vw;
                }
            }
        }

        .values {
            .values-body {
                .values-text {
                    width: 70%;

                    h6 {
                        font-size: 3.5vw;
                    }

                    p {
                        font-size: 3vw;
                        margin-top: 10%;
                    }
                }
            }

            .commitment {
                .commitment-heading {
                    h6 {
                        font-size: 3.5vw;
                    }

                    p {
                        font-size: 3vw;
                    }
                }

                .commitment-body {
                    .commitment-card {
                        width: 100%;

                        h5 {
                            font-size: 3.5vw;
                            margin-top: 5%;
                        }

                        p {
                            font-size: 3vw;
                        }

                        &:hover {

                            h5,
                            p {
                                transform: scale(1.05);
                            }
                        }
                    }
                }

                .last {
                    font-size: 2.5vw;
                }
            }
        }

        .brands {
            .carousel .control-prev.control-arrow:before {
                margin-left: -50%;
            }

            .carousel .control-prev.control-arrow:after {
                margin-right: -50%;
            }

            .brands-body {
                .carousel-body {
                    padding: 0 5%;
                    margin-bottom: 15%;
                }
            }
        }
    }

    .b-p {
        .b-p-card {
            background-size: cover;
            background-position: 50%;

            .b-p-body {
                h5 {
                    font-size: 4vw;
                }

                p {
                    font-size: 2.5vw;
                }

                ul {
                    li {
                        font-size: 2.5vw;
                    }
                }

                button {
                    font-size: 2.5vw;
                    border-radius: 5px;
                    border: 1px solid #fff;
                }
            }

            .b-p-brand {
                h6 {
                    font-size: 3vw;
                    width: calc(100% /3.2);
                    border-radius: 20px 0 20px 0;
                }
            }
        }
    }

    .career {

        .career-title {

            h3 {
                font-size: 5vw;
            }
        }

        .career-body {
            .career-card {
                width: 100%;
                margin: 2% 1%;
                padding: 5%;

                h4 {
                    font-size: 4.5vw;
                }

                p {
                    font-size: 3.5vw;
                }

                .career-btn {
                    button {
                        font-size: 3vw;
                        border: 1px solid $orange;
                    }
                }

            }
        }

        .career-why {
            .career-why-body {
                .career-why-card {
                    width: 100%;
                    padding: 5%;

                    .svg {
                        width: 50px;
                        height: 50px;

                        svg {
                            width: 30px;
                            height: 30px;
                        }
                    }

                    h4 {
                        font-size: 4vw;
                    }

                    p {
                        font-size: 3.5vw;
                    }
                }
            }
        }
    }

    .blog {
        .blog-body {
            .blog-body-content {
                justify-content: flex-start;

                .blog-card {
                    width: calc(100% / 2.1);

                    .blog-card-text {
                        span {
                            border-radius: 2px 2px 0 0;
                            font-size: 2.5vw;
                        }

                        a {
                            font-size: 3vw;
                        }
                    }
                }
            }
        }
    }

    .blog-details {
        width: 100%;

        .blog-card-text {
            h1 {
                font-size: 5vw;
                font-weight: bold;
            }

            h3 {
                font-size: 4.5vw;
            }

            p {
                font-size: 3vw;
            }

            ul,
            ol {
                li {
                    font-size: 3vw;

                }
            }
        }
    }

    .contact {
        .contact-info {
            .contact-info-card {
                border-radius: 5px;

                svg {
                    width: 20px;
                    height: 20px;
                    transform: translate(190%, -30%);
                }

                a {
                    font-size: 2.5vw;
                    word-break: break-all;
                }
            }
        }

        .contact-form {
            flex-direction: column;

            img {
                width: 100%;
                padding-right: 0;
            }

            form {
                width: 100%;

                h3 {
                    font-size: 4vw;
                    font-weight: bold;
                }

                input,
                textarea {
                    font-size: 2.5vw;
                }

                button {
                    font-size: 3vw;
                }
            }
        }
    }

    .privacy-policy {

        h3 {
            font-size: 4vw;
        }

        h4 {
            font-size: 3.5vw;
            margin-top: 1%;
            margin-bottom: 2%;

        }

        p {
            font-size: 3vw;
        }

    }

    footer {
        .footer-body {
            // flex-direction: column;
            flex-wrap: wrap;
            padding: 5%;

            .footer-about,
            .footer-links,
            .footer-contact,
            .footer-map {
                width: 100%;
            }

            .footer-about {
                h2 {
                    font-size: 3.5vw;
                    margin-bottom: 2%;
                }

                p {
                    font-size: 2.5vw;
                }
            }

            .footer-links {
                padding-left: 0;
                margin-bottom: 5%;
                width: 35%;

                h4 {
                    font-size: 3vw;
                    // margin-bottom: 3%;
                }

                .footer-links-content {
                    a {
                        font-size: 2.5vw;
                    }
                }

            }

            .footer-contact {
                margin-bottom: 5%;
                width: 60%;

                h4 {
                    font-size: 3vw;
                    // margin-bottom: 3%;
                }

                .footer-contact-body {
                    .footer-contact-content {
                        a {
                            font-size: 2.5vw
                        }

                        svg {
                            width: 10px;
                            height: 10px;
                        }
                    }
                }

                .footer-follow {
                    a {
                        width: 25px;
                        height: 25px;

                        svg {
                            width: 18px;
                            height: 18px;
                        }
                    }
                }

            }

        }

        .footer-copyright {
            display: block;
            padding: 5%;

            p {
                font-size: 2.5vw;
                width: 100%;
            }

            .footer-copyright-content {
                width: 100%;
                text-align: center;
                margin-top: -5%;

                a {
                    font-size: 2.5vw;
                }
            }
        }
    }
}

@media (min-width: 481px) and (max-width: $md) and (orientation: landscape) {
    .banner {
        margin-top: 10%;

        .banner-caption {
            svg {
                width: 40px;
                height: 40px;
            }

        }
    }

    .heading {
        svg {
            width: 30px;
            height: 30px;
        }

        h4 {
            font-size: 4vw;
        }
    }

    .slider {
        margin-top: 5%;

        .carousel {
            height: 50vh;
        }

        .carousel-inner {

            .carousel-item {
                position: relative;
                height: 50vh;
                visibility: visible;

                video {
                    object-fit: contain !important;
                    width: 100%;
                    height: 50vh !important;
                }

                img {
                    height: 50vh;
                }

                h5 {
                    height: 50vh !important;
                    top: 28% !important;
                    font-size: 4vw !important;

                    ul {
                        li {
                            border-radius: 1px solid #fff !important;
                            font-size: 2.5vw !important;
                        }
                    }
                }
            }
        }
    }

    .home {

        .about {
            flex-direction: column;

            .about-img {
                width: 100%;
            }

            .about-text {
                width: 100%;
                margin-top: 5%;
                padding-left: 0;

                h2 {
                    font-size: 4vw;
                }

                p {
                    font-size: 3vw;
                }
            }
        }

        .brand-portfolio {
            .brand-portfolio-body {
                .brand-portfolio-card {
                    width: calc(100% / 2.1);

                    h5 {
                        font-size: 3vw;
                    }
                }
            }
        }

        .training {
            .training-body {
                flex-direction: column;

                .training-img {
                    width: 100%;

                    &::after {
                        display: none;
                    }
                }

                .training-text {
                    width: 100%;
                    padding-left: 0;
                    margin-top: 5%;

                    p {
                        font-size: 2.5vw;
                    }

                    a {
                        font-size: 2.5vw;
                    }
                }
            }
        }

        .life {
            .life-body {
                flex-direction: column-reverse;

                .life-img {
                    width: 100%;

                    &::after {
                        display: none;
                    }
                }

                .life-text {
                    width: 100%;
                    padding-left: 0;
                    margin-top: 5%;

                    p {
                        font-size: 2.5vw;
                    }

                    a {
                        font-size: 2.5vw;
                    }
                }
            }
        }

        .brands {
            .carousel .control-prev.control-arrow:before {
                margin-left: -50%;
            }

            .carousel .control-prev.control-arrow:after {
                margin-right: -50%;
            }

            .brands-body {
                .carousel-body {
                    margin-bottom: 15%;
                }
            }
        }

        .home-blog {
            .blog-body {
                flex-direction: column;

                .blog-card {
                    width: calc(100%);
                    margin-bottom: 5%;

                    .blog-text {
                        a {
                            font-size: 4vw;
                        }

                        p,
                        .read {
                            font-size: 3vw;

                            svg {
                                width: 10px;
                                height: 10px;
                            }
                        }
                    }
                }
            }
        }
    }

    .navbar {
        .header {
            .header-logo {
                img {
                    width: 150%;
                }
            }

            .header-links {
                display: none;
            }

            .menu {
                display: block;
            }
        }
    }

    .home-navbar {
        display: none;

        .header {
            .menu {
                display: block;
            }
        }
    }

    .mobile {
        display: flex;
    }

    .about-us {
        .about {
            flex-direction: column;

            .about-img {
                width: 100%;
            }

            .about-text {
                width: 100%;
                padding-left: 0;
                margin-top: 5%;

                p {
                    font-size: 2.5vw;
                }
            }
        }

        .achievements {
            padding: 2%;

            .a-card {
                svg {
                    width: 50px;
                    height: 50px;
                }

                h4 {
                    font-size: 3vw;
                }

                p {
                    font-size: 2vw;
                }
            }
        }

        .values {
            .values-body {
                .values-text {
                    width: 70%;

                    h6 {
                        font-size: 3.5vw;
                    }

                    p {
                        font-size: 3vw;
                        margin-top: 10%;
                    }
                }
            }

            .commitment {
                .commitment-heading {
                    h6 {
                        font-size: 3.5vw;
                    }

                    p {
                        font-size: 2.5vw;
                    }
                }

                .commitment-body {
                    .commitment-card {
                        width: 100%;

                        h5 {
                            font-size: 3vw;
                            margin-top: 5%;
                        }

                        p {
                            font-size: 2.5vw;
                        }

                        &:hover {

                            h5,
                            p {
                                transform: scale(1.05);
                            }
                        }
                    }
                }

                .last {
                    font-size: 2.5vw;
                }
            }
        }
    }

    .b-p {
        .b-p-card {
            background-size: cover;
            background-position: 50%;

            .b-p-body {
                h5 {
                    font-size: 4vw;
                }

                p {
                    font-size: 2.5vw;
                }

                button {
                    font-size: 2.5vw;
                    border-radius: 5px;
                    border: 1px solid #fff;
                }
            }

            .b-p-brand {
                h6 {
                    font-size: 3vw;
                    width: calc(100% /3.2);
                    border-radius: 20px 0 20px 0;
                }
            }
        }
    }

    .career {

        .career-title {

            h3 {
                font-size: 5vw;
            }
        }

        .career-body {
            .career-card {
                width: calc(100% / 2.1);
                margin: 2% 1%;

                h4 {
                    font-size: 3.5vw;
                }

                p {
                    font-size: 2.5vw;
                }

                .career-btn {
                    button {
                        font-size: 2vw;
                        border: 1px solid $orange;
                    }
                }

            }
        }

        .career-why {
            .career-why-body {
                .career-why-card {
                    width: calc(100% / 2.1);

                    .svg {
                        width: 50px;
                        height: 50px;

                        svg {
                            width: 30px;
                            height: 30px;
                        }
                    }

                    h4 {
                        font-size: 3vw;
                    }

                    p {
                        font-size: 2.3vw;
                    }
                }
            }
        }
    }

    .blog {
        .blog-body {
            .blog-body-content {
                justify-content: flex-start;

                .blog-card {
                    width: calc(100% / 2.1);

                    .blog-card-text {
                        span {
                            border-radius: 2px 2px 0 0;
                            font-size: 2.5vw;
                        }

                        a {
                            font-size: 3vw;
                        }
                    }
                }
            }
        }
    }

    .blog-details {
        width: 100%;

        .blog-card-text {
            h1 {
                font-size: 5vw;
                font-weight: bold;
            }

            h3 {
                font-size: 4.5vw;
            }

            p {
                font-size: 3vw;
            }

            ul,
            ol {
                li {
                    font-size: 3vw;

                }
            }
        }
    }

    .contact {
        .contact-info {
            .contact-info-card {
                border-radius: 5px;

                svg {
                    width: 20px;
                    height: 20px;
                    transform: translate(190%, -30%);
                }

                a {
                    font-size: 2.5vw;
                    word-break: break-all;
                }
            }
        }

        .contact-form {
            flex-direction: column;

            img {
                width: 100%;
                padding-right: 0;
            }

            form {
                width: 100%;

                h3 {
                    font-size: 4vw;
                    font-weight: bold;
                }

                input,
                textarea {
                    font-size: 2.5vw;
                }

                button {
                    font-size: 3vw;
                }
            }
        }
    }

    .privacy-policy {

        h3 {
            font-size: 4vw;
        }

        h4 {
            font-size: 3.5vw;

        }

        p {
            font-size: 2.5vw;
        }

    }

    footer {
        .footer-body {
            // flex-direction: column;
            flex-wrap: wrap;
            padding: 5%;

            .footer-about,
            .footer-links,
            .footer-contact,
            .footer-map {
                width: 100%;
            }

            .footer-about {
                h2 {
                    font-size: 3.5vw;
                    margin-bottom: 2%;
                }

                p {
                    font-size: 2.5vw;
                }
            }

            .footer-links {
                padding-left: 0;
                margin-bottom: 5%;
                width: 35%;

                h4 {
                    font-size: 3vw;
                    // margin-bottom: 3%;
                }

                .footer-links-content {
                    a {
                        font-size: 2.5vw;
                    }
                }

            }

            .footer-contact {
                margin-bottom: 5%;
                width: 60%;

                h4 {
                    font-size: 3vw;
                    // margin-bottom: 3%;
                }

                .footer-contact-body {
                    .footer-contact-content {
                        a {
                            font-size: 2.5vw
                        }

                        svg {
                            width: 10px;
                            height: 10px;
                        }
                    }
                }

                .footer-follow {
                    a {
                        width: 25px;
                        height: 25px;

                        svg {
                            width: 18px;
                            height: 18px;
                        }
                    }
                }

            }

        }

        .footer-copyright {
            display: block;
            padding: 5%;

            p {
                font-size: 2.5vw;
                width: 100%;
            }

            .footer-copyright-content {
                width: 100%;
                text-align: center;
                margin-top: -5%;

                a {
                    font-size: 2.5vw;
                }
            }
        }
    }
}

@media (min-width: 481px) and (max-width: $md) and (orientation: portrait) {
    .banner {
        margin-top: 10%;

        .banner-caption {
            svg {
                width: 40px;
                height: 40px;
            }

        }
    }

    .heading {
        svg {
            width: 30px;
            height: 30px;
        }

        h4 {
            font-size: 4vw;
        }
    }

    .slider {
        .carousel {
            height: 50vh;
        }

        .carousel-inner {

            .carousel-item {
                position: relative;
                height: 50vh;
                visibility: visible;

                video {
                    object-fit: contain;
                    width: 100%;
                    height: 50vh !important;
                }

                img {
                    height: 50vh;
                }

                h5 {
                    height: 50vh !important;
                    top: 28% !important;
                    font-size: 4vw !important;

                    ul {
                        li {
                            border-radius: 1px solid #fff !important;
                            font-size: 2.5vw !important;
                        }
                    }
                }
            }
        }
    }

    .home {

        .about {
            flex-direction: column;

            .about-img {
                width: 100%;
            }

            .about-text {
                width: 100%;
                margin-top: 5%;
                padding-left: 0;

                h2 {
                    font-size: 4vw;
                }

                p {
                    font-size: 3vw;
                }
            }
        }

        .brand-portfolio {
            .brand-portfolio-body {
                .brand-portfolio-card {
                    width: calc(100% / 2.1);

                    h5 {
                        font-size: 3vw;
                    }
                }
            }
        }

        .training {
            .training-body {
                flex-direction: column;

                .training-img {
                    width: 100%;

                    &::after {
                        display: none;
                    }
                }

                .training-text {
                    width: 100%;
                    padding-left: 0;
                    margin-top: 5%;

                    p {
                        font-size: 2.5vw;
                    }

                    a {
                        font-size: 2.5vw;
                    }
                }
            }
        }

        .life {
            .life-body {
                flex-direction: column-reverse;

                .life-img {
                    width: 100%;

                    &::after {
                        display: none;
                    }
                }

                .life-text {
                    width: 100%;
                    padding-left: 0;
                    margin-top: 5%;

                    p {
                        font-size: 2.5vw;
                    }

                    a {
                        font-size: 2.5vw;
                    }
                }
            }
        }

        .brands {
            .carousel .control-prev.control-arrow:before {
                margin-left: -50%;
            }

            .carousel .control-prev.control-arrow:after {
                margin-right: -50%;
            }

            .brands-body {
                .carousel-body {
                    margin-bottom: 15%;
                }
            }
        }

        .home-blog {
            .blog-body {
                flex-direction: column;

                .blog-card {
                    width: calc(100%);
                    margin-bottom: 5%;

                    .blog-text {
                        a {
                            font-size: 4vw;
                        }

                        p,
                        .read {
                            font-size: 3vw;

                            svg {
                                width: 10px;
                                height: 10px;
                            }
                        }
                    }
                }
            }
        }
    }

    .navbar {
        .header {
            .header-logo {
                img {
                    width: 150%;
                }
            }

            .header-links {
                display: none;
            }

            .menu {
                display: block;
            }
        }
    }

    .home-navbar {
        display: none;

        .header {
            .menu {
                display: block;
            }
        }
    }

    .mobile {
        display: flex;
    }

    .about-us {
        .about {
            flex-direction: column;

            .about-img {
                width: 100%;
            }

            .about-text {
                width: 100%;
                padding-left: 0;
                margin-top: 5%;

                p {
                    font-size: 2.5vw;
                }
            }
        }

        .achievements {
            padding: 2%;

            .a-card {
                svg {
                    width: 50px;
                    height: 50px;
                }

                h4 {
                    font-size: 3vw;
                }

                p {
                    font-size: 2vw;
                }
            }
        }

        .values {
            .values-body {
                .values-text {
                    width: 70%;

                    h6 {
                        font-size: 3.5vw;
                    }

                    p {
                        font-size: 3vw;
                        margin-top: 10%;
                    }
                }
            }

            .commitment {
                .commitment-heading {
                    h6 {
                        font-size: 3.5vw;
                    }

                    p {
                        font-size: 2.5vw;
                    }
                }

                .commitment-body {
                    .commitment-card {
                        width: 100%;

                        h5 {
                            font-size: 3vw;
                            margin-top: 5%;
                        }

                        p {
                            font-size: 2.5vw;
                        }

                        &:hover {

                            h5,
                            p {
                                transform: scale(1.05);
                            }
                        }
                    }
                }

                .last {
                    font-size: 2.5vw;
                }
            }
        }
    }

    .b-p {
        .b-p-card {
            background-size: cover;
            background-position: 50%;

            .b-p-body {
                h5 {
                    font-size: 4vw;
                }

                p {
                    font-size: 2.5vw;
                }

                button {
                    font-size: 2.5vw;
                    border-radius: 5px;
                    border: 1px solid #fff;
                }
            }

            .b-p-brand {
                h6 {
                    font-size: 3vw;
                    width: calc(100% /3.2);
                    border-radius: 20px 0 20px 0;
                }
            }
        }
    }

    .career {

        .career-title {

            h3 {
                font-size: 5vw;
            }
        }

        .career-body {
            .career-card {
                width: calc(100% / 2.1);
                margin: 2% 1%;

                h4 {
                    font-size: 3.5vw;
                }

                p {
                    font-size: 2.5vw;
                }

                .career-btn {
                    button {
                        font-size: 2vw;
                        border: 1px solid $orange;
                    }
                }

            }
        }

        .career-why {
            .career-why-body {
                .career-why-card {
                    width: calc(100% / 2.1);

                    .svg {
                        width: 50px;
                        height: 50px;

                        svg {
                            width: 30px;
                            height: 30px;
                        }
                    }

                    h4 {
                        font-size: 3vw;
                    }

                    p {
                        font-size: 2.3vw;
                    }
                }
            }
        }
    }

    .blog {
        .blog-body {
            .blog-body-content {
                justify-content: flex-start;

                .blog-card {
                    width: calc(100% / 2.1);

                    .blog-card-text {
                        span {
                            border-radius: 2px 2px 0 0;
                            font-size: 2.5vw;
                        }

                        a {
                            font-size: 3vw;
                        }
                    }
                }
            }
        }
    }

    .blog-details {
        width: 100%;

        .blog-card-text {
            h1 {
                font-size: 5vw;
                font-weight: bold;
            }

            h3 {
                font-size: 4.5vw;
            }

            p {
                font-size: 3vw;
            }

            ul,
            ol {
                li {
                    font-size: 3vw;

                }
            }
        }
    }

    .contact {
        .contact-info {
            .contact-info-card {
                border-radius: 5px;

                svg {
                    width: 20px;
                    height: 20px;
                    transform: translate(190%, -30%);
                }

                a {
                    font-size: 2.5vw;
                    word-break: break-all;
                }
            }
        }

        .contact-form {
            flex-direction: column;

            img {
                width: 100%;
                padding-right: 0;
            }

            form {
                width: 100%;

                h3 {
                    font-size: 4vw;
                    font-weight: bold;
                }

                input,
                textarea {
                    font-size: 2.5vw;
                }

                button {
                    font-size: 3vw;
                }
            }
        }
    }

    .privacy-policy {

        h3 {
            font-size: 4vw;
        }

        h4 {
            font-size: 3.5vw;

        }

        p {
            font-size: 2.5vw;
        }

    }

    footer {
        .footer-body {
            // flex-direction: column;
            flex-wrap: wrap;
            padding: 5%;

            .footer-about,
            .footer-links,
            .footer-contact,
            .footer-map {
                width: 100%;
            }

            .footer-about {
                h2 {
                    font-size: 3.5vw;
                    margin-bottom: 2%;
                }

                p {
                    font-size: 2.5vw;
                }
            }

            .footer-links {
                padding-left: 0;
                margin-bottom: 5%;
                width: 35%;

                h4 {
                    font-size: 3vw;
                    // margin-bottom: 3%;
                }

                .footer-links-content {
                    a {
                        font-size: 2.5vw;
                    }
                }

            }

            .footer-contact {
                margin-bottom: 5%;
                width: 60%;

                h4 {
                    font-size: 3vw;
                    // margin-bottom: 3%;
                }

                .footer-contact-body {
                    .footer-contact-content {
                        a {
                            font-size: 2.5vw
                        }

                        svg {
                            width: 10px;
                            height: 10px;
                        }
                    }
                }

                .footer-follow {
                    a {
                        width: 25px;
                        height: 25px;

                        svg {
                            width: 18px;
                            height: 18px;
                        }
                    }
                }

            }

        }

        .footer-copyright {
            display: block;
            padding: 5%;

            p {
                font-size: 2.5vw;
                width: 100%;
            }

            .footer-copyright-content {
                width: 100%;
                text-align: center;
                margin-top: -5%;

                a {
                    font-size: 2.5vw;
                }
            }
        }
    }
}

@media (min-width: 768px) and (max-width: $lg) and (orientation: landscape) {}

@media (min-width: 768px) and (max-width: $lg) and (orientation: portrait) {}

@media (min-width: 1025px) and (max-width: $xl) {}