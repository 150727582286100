@import 'variables.scss';
@import 'mixins.scss';
@import 'animations.scss';
@import url('https://fonts.googleapis.com/css2?family=Catamaran&family=Signika&display=swap');

/*===============================================================
# Common
===============================================================*/
a {
    color: inherit;
    text-decoration: none;
}

a,
li,
ul,
p,
button {
    font-family: 'Signika', sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: 'Catamaran', sans-serif;
}

.heading {
    @include flex;
    align-items: center;
    margin: 3% 0;

    h3,
    h2 {
        font-size: 3.5vw;
        text-transform: uppercase;
        color: $orange;
        font-weight: 800;
        font-family: 'Catamaran', sans-serif;
        margin-bottom: 0;
        // letter-spacing: 2px;
    }

    svg {
        height: 50px;
        width: 50px;
        fill: $yellow;
        margin: 0 2%;
    }
}


/*===============================================================
# Banner
===============================================================*/
.banner {
    margin-top: 5%;
    position: relative;

    img {
        width: 100%;
        object-fit: cover;
        filter: brightness(60%);
    }

    .banner-caption {
        @include center-position;
        @include flex;
        align-items: center;
        width: 100%;

        h1 {
            text-shadow: $text-shadow;
            font-size: 6vw;
            text-transform: uppercase;
            color: #fff;
            font-weight: bold;
            margin-bottom: 0;
            letter-spacing: 4px;
        }

        svg {
            height: 80px;
            width: 80px;
            fill: #fff;
            margin: 0 2%;
            filter: drop-shadow(2px 2px 5px rgba(0, 0, 0, 0.3));
        }
    }


}

/*===============================================================
# Navbar
===============================================================*/

.home-navbar,
.navbar {
    background: rgba($color: #fff, $alpha: 0.8);
    position: fixed !important;
    top: 5%;
    left: 0;
    width: 90%;
    transform: translate(5%, 0);
    z-index: 10;
    padding: 0;
    box-shadow: 2px 2px 5px rgba($color: #000, $alpha: 0.2);
    border-radius: 10px;

    .header {
        // background: rgba($color: #fff, $alpha: 0.8);
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
        padding: 2% 0;
        // border-radius: 20px;

        .menu {
            width: 20px;
            height: 20px;
            display: none;
            margin-left: auto;
            margin-right: 5%;
            fill: $orange;
        }

        .header-logo {
            width: 30%;
            padding: 0 2%;

            img {
                width: 40%;
            }
        }

        .header-links {
            width: 80%;
            text-transform: uppercase;
            @include flex;
            align-items: center;
            justify-content: flex-end;
            margin-right: 1%;
            flex-grow: 1;

            // a {
            //     font-size: 1vw;
            //     margin-right: 2%;
            //     width: 80%;
            // }

            a {
                position: relative;
                z-index: 2;
                margin-right: 5%;
                font-size: 1.1vw;
                padding: 1%;
                font-weight: 700;
                color: #000;
                // width: 100%;

                // &:first-child{
                //     width: 50%;
                // }

                // &:last-child {
                //     border-radius: 40px;
                //     outline: 0;
                //     font-size: 1.1vw;
                //     font-weight: bold;
                //     background: $gradient;
                //     color: #fff;
                //     filter: drop-shadow(2px 2px 5px rgba(0, 0, 0, 0.2));
                //     border: none;
                //     padding: 1% 3%;
                //     transition: all .3s ease-out;
                //     box-shadow: inset 0 -4px 0 0 rgba(0, 0, 0, .2);
                //     text-align: center;
                //     width: 70%;

                //     &:hover {
                //         color: #fff;

                //         &:after {
                //             display: none;
                //         }
                //     }

                // }

                &:after {
                    content: '';
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    background: $gradient;
                    height: 2px;
                    display: none;
                    // animation: dude .75s 1 forwards;
                }

                &:hover {
                    // transform: scale(1.1);
                    font-weight: bold;
                    transition: .5s all ease;
                    color: $orange;

                    &:after {
                        transition: .5s all ease;
                        display: block;
                    }

                }
            }

            .dropdown {
                padding: 1%;
                position: relative;
                display: inline-block;
                margin-top: -5.5px;
                width: 100%;
                // margin-right: 2%;


                a {
                    font-size: 1vw;
                }

                // margin-right: 5%;
                &:hover {

                    .dropdown-content {
                        display: flex;
                        flex-direction: column;
                    }

                    a {
                        &:after {
                            display: none;
                        }
                    }
                }


                .dropdown-content {
                    display: none;
                    flex-direction: column;
                    position: absolute;
                    top: 100%;
                    left: -15%;
                    background: #fff;
                    box-shadow: $box-shadow;
                    border-radius: 5px;
                    width: 100%;
                    animation: slide-in-up .7s;

                    a {
                        color: #000;
                        font-size: 1vw;
                        width: 100%;
                        padding: 7% 10%;

                        &:first-child {
                            border-radius: 5px 5px 0 0;
                        }

                        &:last-child {
                            background: transparent;
                            box-shadow: none;
                            text-align: left;
                            border-radius: 0;
                        }

                        &:hover {
                            // background: $orange;
                            // color: #fff;
                            transform: translateX(10px);
                            color: $orange;

                            &::after {
                                display: none;
                            }
                        }
                    }
                }
            }
        }

        .header-mobile {
            display: none;
            flex-direction: column;
            position: fixed;
            right: 0;
            top: 0;
            width: 70%;
            height: 100%;
            box-shadow: $box-shadow;
            background: #fff !important;
            padding: 5%;

            .cross {
                width: 30px;
                height: 30px;
                margin-left: auto;
                margin-right: 5%;
                fill: $orange;
            }

            .header-mobile-content {
                display: flex;
                flex-direction: column;
                margin-top: 15%;

                a {
                    margin-bottom: 5%;
                }
            }
        }
    }


}

.mobile {
    background: #fff;
    display: none;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    padding: 4% 0;
    position: fixed !important;
    top: 0;
    left: 0;
    z-index: 4000;
    box-shadow: $box-shadow;

    .menu {
        width: 20px;
        height: 20px;
        margin-left: auto;
        margin-right: 5%;
        fill: $orange;
    }

    .header-logo {
        width: 20%;
        padding: 0 2%;

        img {
            width: 150%;
        }
    }

    .header-links {
        width: 80%;
        text-transform: uppercase;
        @include flex;
        align-items: center;
        justify-content: flex-end;
        margin-right: 5%;

        a {
            position: relative;
            z-index: 2;
            margin: 0 .5%;
            font-size: 1.1vw;
            padding: 1%;
            font-weight: 700;
            position: relative;
            color: #000;

            &:after {
                content: '';
                position: absolute;
                bottom: 0;
                left: 10%;
                background: $gradient;
                height: 2px;
                display: none;
                animation: dude .75s 1 forwards;
            }

            &:hover {
                transform: scale(1.1);
                font-weight: bold;
                transition: .5s all ease;
                color: $orange;

                &:after {
                    transition: .5s all ease;
                    display: block;
                }

            }

            @keyframes dude {
                0% {
                    width: 0;
                }

                100% {
                    width: 80%;
                }
            }
        }
    }

    .header-mobile {
        display: none;
        flex-direction: column;
        position: fixed;
        right: 0;
        top: 0;
        width: 70%;
        height: 100%;
        box-shadow: $box-shadow;
        background: #fff;
        padding: 5%;
        z-index: 3000;

        .cross {
            width: 30px;
            height: 30px;
            margin-left: auto;
            margin-right: 5%;
            fill: $orange;
        }

        .header-mobile-content {
            display: flex;
            flex-direction: column;
            margin-top: 15%;

            a {
                margin-bottom: 5%;
            }
        }
    }
}


/*===============================================================
# carousel
===============================================================*/

.slider {
    margin-top: -5%;

    .carousel {
        background: #fff;
        // height: 110vh;

        .carousel-inner {

            .carousel-item {
                position: relative;
                height: 100vh;
                visibility: visible;

                video {
                    object-fit: cover;
                    width: 100%;
                    height: 100%;
                }

                img {
                    width: 100%;
                    object-fit: cover;
                    filter: brightness(80%);
                }

                h5 {
                    font-size: 3vw;
                    text-transform: capitalize;
                    text-shadow: 2px 2px 2px rgba($color: #000, $alpha: 0.2);
                    margin-bottom: 5%;
                    font-weight: bolder;
                    text-align: center;
                    width: 100%;
                    height: 100%;
                    color: #fff;
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                    padding: 5%;
                    border-radius: 10px;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    padding: 0 10%;
                    line-height: 150%;

                    ul {
                        @include flex;
                        width: 80%;
                        margin-top: 3%;

                        li {
                            list-style-type: none;
                            margin: 1%;
                            border: 2px solid #fff;
                            padding: .5% 5%;
                            background: $gradient;
                            font-size: 2vw;
                            border-radius: 10px;
                            width: calc(100% / 3);
                        }
                    }
                }

                &:last-child {
                    h5 {
                        ul {
                            margin-top: 1%;

                            li {
                                width: 80%;
                            }
                        }
                    }
                }
            }
        }
    }

    .carousel-control-prev-icon {
        margin-left: -55%;
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e");

    }

    .carousel-control-next-icon {
        margin-right: -55%;
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
    }
}

/*===============================================================
# Home
===============================================================*/
.about {
    padding: $padding;
    @include flex;
    margin-top: 5%;

    .about-img {
        width: 40%;
        position: relative;


        img {
            width: 100%;
            height: 80%;
            object-fit: cover;
            margin-right: auto;
            display: block;
            border: 2px solid $yellow;
            padding: 3%;
            background-color: #fff;
            box-shadow: $box-shadow;
            animation: slide-in-left 2s forwards;
        }

        .about-before {
            position: absolute;
            top: 5%;
            right: 5%;
            width: 100%;
            height: 80%;
            border-bottom: 5px solid $yellow;
            border-left: 5px solid $yellow;
            z-index: -1;
            background: transparent;
            border-radius: 5px;
        }



    }

    .about-text {
        width: 60%;
        padding-left: 5%;


        h2 {
            font-size: 1.5vw;
            margin: 0;
            color: $orange;
            border-bottom: 1px solid rgba($color: $orange, $alpha: 0.3);
            padding-bottom: 2%;
        }

        p {
            font-size: 1.13vw;
            line-height: 170%;
            margin-top: 2%;
            color: $grey;

        }
    }
}

.brand-portfolio {
    padding: $padding;

    .brand-portfolio-body {
        @include flex;
        flex-wrap: wrap;

        .brand-portfolio-card {
            width: calc(100% / 6.9);
            margin: 1%;
            box-shadow: $box-shadow;
            position: relative;
            border-radius: 5px;

            img {
                width: 100%;
                object-fit: contain;
                border-radius: 5px;
            }

            h5 {
                color: #fff;
                font-size: 1.1vw;
                padding: 3% 5%;
                text-align: center;
                position: absolute;
                left: 0;
                bottom: 2%;
                width: 80%;
                background: $transparent-gradient;
                text-shadow: $text-shadow;
                z-index: 2;
            }


            svg {
                position: fixed;
                top: 5%;
                right: 5%;
                width: 25px;
                height: 25px;
                cursor: pointer;
                fill: #fff;
                box-shadow: $box-shadow;
                background: $orange;
                border-radius: 50%;
                z-index: 1;
            }

            .b-p-brand {
                position: fixed;
                top: 5%;
                right: 5%;
                width: 25px;
                height: 25px;
                cursor: pointer;

                &:hover {
                    .b-p-brand-content {
                        display: flex;
                        // width: 800%;
                        transition: $transition;
                    }
                }

                svg {
                    width: 100%;
                    height: 100%;
                    fill: #fff;
                    box-shadow: $box-shadow;
                    background: $orange;
                    border-radius: 50%;
                    z-index: 1;
                }

                .b-p-brand-content {
                    // display: flex;
                    flex-wrap: wrap;
                    position: absolute;
                    top: -20%;
                    left: -550%;
                    height: 40vh;
                    width: 80vh;
                    background: $orange;
                    z-index: 999999;
                    // width: 10%;
                    display: none;
                    padding: 1em;
                    border-radius: 10px;

                    h6 {
                        color: #fff;
                        margin: 1%;
                        font-size: 1vw;
                        width: calc(100% / 5);
                    }
                }
            }





        }
    }

    .modal {
        .modal-content {
            width: 200%;
            top: 50%;
            left: 50%;
            transform: translate(-50%, 0);
            background: $gradient;

            .modal-header {
                h1 {
                    color: #fff;
                    text-shadow: $text-shadow;
                }
            }
        }

        .modal-body {
            display: flex;
            justify-content: center;
            flex-wrap: wrap;

            h6 {
                color: $yellow;
                font-weight: bold;
                letter-spacing: 1px;
                border-radius: 10px 0 10px 0;
                background: #fff;
                margin: 1%;
                width: calc(100% /5.3);
                font-size: 1.1vw;
                box-shadow: $box-shadow;
                padding: 2%;
            }
        }
    }
}

.training {
    padding: $padding;

    .training-body {
        @include flex;

        .training-img {
            width: 40%;
            position: relative;

            &::after {
                background: $orange;
                width: 100%;
                height: 90%;
                content: "";
                position: absolute;
                top: 8%;
                left: -5%;
                z-index: -1;
            }

            img {
                width: 100%;
                height: 90%;
                object-fit: cover;
                box-shadow: $box-shadow;
            }
        }

        .training-text {
            width: 60%;
            padding-left: 5%;

            p {
                font-size: 1.2vw;
                line-height: 200%;
                margin-bottom: 2%;
            }

            a {
                color: #fff;
                background: $orange;
                font-size: 1.2vw;
                border: 1px solid $yellow;
                padding: 1% 5%;
                text-align: center;
                border-radius: 10px 0 10px 0;
            }
        }
    }
}

.life {
    padding: $padding;

    .life-body {
        @include flex;

        .life-img {
            width: 40%;
            position: relative;

            &::after {
                background: $orange;
                width: 100%;
                height: 100%;
                content: "";
                position: absolute;
                top: 5%;
                left: 5%;
                z-index: -1;
            }

            img {
                width: 100%;
                height: 100%;
                box-shadow: $box-shadow;
                object-fit: cover;
            }
        }

        .life-text {
            width: 60%;
            padding: 2% 5% 2% 0;

            p {
                font-size: 1.3vw;
                line-height: 200%;
            }
        }
    }
}

.brands {
    padding: $padding;
    margin-top: -3%;

    .carousel.carousel-slider .control-arrow {
        height: 80%;

        &:hover {
            background: transparent;
        }
    }

    .carousel .control-prev.control-arrow:before {
        border-right: 8px solid $orange;
    }

    .carousel .control-next.control-arrow:before {
        border-left: 8px solid $orange;
    }

    .carousel .control-dots .dot {
        box-shadow: 1px 1px 5px $orange;
        border: 2px solid $orange;
        width: 15px;
        height: 15px;
    }

    .carousel {
        .carousel-wrapper {
            width: 100%;

            .carousel-body {
                @include flex;
                margin: 3% 5%;
                flex-wrap: wrap;

                .testimonial-card {
                    width: calc(100% / 3.2);
                    margin: 1% 1% 3% 1%;
                    // height: 40vh;
                    box-shadow: $box-shadow;
                    padding: 2% 5%;
                    color: #fff;
                    background: #fff;
                    position: relative;

                    img {
                        width: 100%;
                        display: flex;
                       align-items: center;
                       justify-content: center;
                    }

                    p {
                        text-align: left;
                        font-size: 1.05vw;
                    }

                    h6 {
                        font-size: 2vw;
                        margin-bottom: 5%;
                        text-align: center;
                    }
                }
            }
        }
    }

}

.home-blog {
    padding: $padding;

    .blog-body {
        @include flex;

        .blog-card {
            width: calc(100% / 3);
            box-shadow: $box-shadow;
            margin: 1%;

            img {
                width: 100%;
            }

            .blog-text {
                padding: 5%;
                position: relative;
                display: block;

                .blog-text-before {
                    position: absolute;
                    top: -13%;
                    left: 0;
                    width: 0;
                    height: 0;
                    border-left: 0 solid transparent;
                    border-right: 190px solid transparent;
                    border-bottom: 30px solid #fff;
                    display: block;
                }

                .blog-text-after {
                    position: absolute;
                    top: -13%;
                    right: 0;
                    width: 0;
                    height: 0;
                    border-left: 190px solid transparent;
                    border-right: 0 solid transparent;
                    border-bottom: 20px solid $orange;
                    display: block;
                }

                a {
                    color: $orange;
                    font-size: 1.3vw;
                    margin-bottom: 3%;
                    display: block;
                }

                p {
                    font-size: 1vw;
                    color: $grey;
                }

                .read {
                    font-size: 1.2vw;
                    color: #000;

                    svg {
                        width: 15px;
                        height: 15px;
                        fill: #000;
                        margin-left: 5%;
                    }

                    &:hover {
                        color: $orange;
                        transition: $transition;
                        font-size: 1.4vw;

                        svg {
                            transition: $transition;
                            fill: $orange;
                        }

                    }
                }
            }

        }
    }
}

/*===============================================================
# About
===============================================================*/
.about-us {
    // padding: $padding;
    margin-top: 5%;

    .about {
        @include flex;

        .about-img {
            width: 40%;
            position: relative;

            img {
                width: 100%;
                height: 80%;
                object-fit: cover;
                margin-right: auto;
                display: block;
                border: 2px solid $yellow;
                padding: 3%;
                background-color: #fff;
                box-shadow: $box-shadow;
                animation: slide-in-left 2s forwards;
            }

            .about-before {
                position: absolute;
                top: 5%;
                right: 5%;
                width: 100%;
                height: 80%;
                border-bottom: 5px solid $yellow;
                border-left: 5px solid $yellow;
                z-index: -1;
                background: transparent;
                border-radius: 5px;
            }



        }

        .about-text {
            width: 60%;
            padding-left: 5%;


            h3 {
                font-size: 1.5vw;
                margin: 0;
                color: $orange;
                border-bottom: 1px solid rgba($color: $orange, $alpha: 0.3);
                padding-bottom: 2%;
            }

            p {
                font-size: 1.2vw;
                line-height: 170%;
                margin-top: 2%;
                color: $grey;

            }
        }
    }

    .achievements {
        padding: $padding;
        align-items: stretch;
        background: $transparent-gradient;
        @include flex;
        margin-top: 5%;
        padding: 5%;

        .a-card {
            color: #fff;
            margin: 0 2%;
            text-align: center;
            width: 25%;

            svg {
                fill: #fff;
                width: 100px;
                height: 100px;
            }

            h4 {
                display: flex;
                font-size: 2vw;
                justify-content: center;
                margin: 5% 0;
            }

            p {
                font-size: 1.2vw;
                text-transform: capitalize;
            }
        }
    }

    .brands {
        margin-top: 5%;
        padding: 0;

        .brands-body {
            padding: $padding;
        }
    }

    .vision {
        .mission-body {
            flex-direction: row-reverse;

            p {
                padding-left: 0;
                color: #000 !important;
            }

            img {
                margin-right: 5%;
            }
        }
    }

    .mission {

        .mission-body {
            @include flex;
            padding: $padding;

            img {
                width: 40%;
                object-fit: cover;
                box-shadow: $box-shadow;
            }

            p {
                width: 60%;
                padding: 0 5%;
                font-size: 1.1vw;
                line-height: 180%;
                // letter-spacing: 1px;
                // color: #fff;
            }
        }
    }

    .values {

        .values-body {
            position: relative;
            box-shadow: $box-shadow;
            margin: 3%;

            &:nth-child(odd) {
                .values-text {
                    left: 0;
                }
            }

            img {
                width: 100%;
                height: 70vh;
                object-fit: cover;
            }

            h6 {
                font-size: 1.5vw;
                margin-bottom: 7%;
                position: relative;
                font-weight: bold;
                text-shadow: $text-shadow;
                color: #fff;

                &:after {
                    content: "";
                    width: 80%;
                    height: 3px;
                    background: $yellow;
                    position: absolute;
                    bottom: -55%;
                    left: 0;
                }
            }

            .values-text {
                display: flex;
                justify-content: center;
                align-items: center;
                position: absolute;
                padding: 5%;
                top: 50%;
                right: 0;
                width: 50%;
                transform: translate(0, -50%);
                background: rgba($color: $grey, $alpha: 0.7);
                height: 100%;

                p {
                    font-size: 1.3vw;
                    color: #fff;

                }
            }

        }

        .commitment {
            .commitment-heading {
                background: $gradient;
                padding: 3%;
                color: #fff;

                h3 {
                    font-weight: bold;
                }

                h6 {
                    font-size: 2.4vw;

                    font-weight: bold;
                    position: relative;
                    margin-bottom: 3%;

                    &:after {
                        content: "";
                        width: 40%;
                        height: 1px;
                        background: #fff;
                        position: absolute;
                        bottom: -45%;
                        left: 0;
                    }
                }

                p {
                    font-size: 1.3vw;
                    color: #fff;
                }
            }

            .commitment-body {
                padding: 5%;
                @include flex;
                flex-wrap: wrap;

                .commitment-card {
                    box-shadow: $box-shadow;
                    width: calc(100% /2.1);
                    height: 40vh;
                    margin: 1%;
                    padding: 2% 5%;
                    position: relative;

                    svg {
                        width: 60px;
                        height: 60px;
                        fill: $orange;
                        margin-bottom: 5%;
                        box-shadow: $box-shadow;
                        padding: 2%;
                        border-radius: 10px;
                    }

                    h4 {
                        color: $orange;
                        font-size: 1.5vw;
                        font-weight: bold;
                        text-transform: capitalize;
                    }

                    p {
                        font-size: 1vw;
                    }

                    &:after {
                        content: "";
                        width: 10%;
                        height: 20%;
                        border-radius: 50px 0 0 0;
                        position: absolute;
                        bottom: 0;
                        right: 0;
                        background: $gradient;
                    }

                    &:hover {

                        &:after {
                            transition: $transition;
                            width: 100%;
                            height: 100%;
                            z-index: -1;
                            border-radius: 0;
                        }

                        svg {
                            transform: scale(1.1);
                            transition: $transition;
                            background: #fff;

                        }

                        h4,
                        p {
                            color: #fff;
                            transform: scale(1.1) translateX(20px);
                            transition: $transition;

                        }
                    }
                }
            }

            .last {
                font-size: 1.1vw;
                padding: 0 6%;
                margin-top: -2%;
            }
        }

        .strenghtening {
            .commitment-heading {

                h6 {

                    &:after {
                        content: "";
                        width: 70%;
                        height: 1px;
                        background: #fff;
                        position: absolute;
                        bottom: -45%;
                        left: 0;
                    }
                }
            }
        }
    }

}

/*===============================================================
# Brand Portfolio
===============================================================*/

.b-p {
    padding: 0;

    .b-p-card {
        padding: 5%;
        background-repeat: no-repeat;
        background-attachment: fixed;
        color: #fff;
        width: 100%;
        background-size: cover;

        .b-p-body {
            background: rgba($color: $grey, $alpha: 0.6);
            padding: 5%;

            h3 {
                font-size: 3vw;
                font-weight: bold;
                margin-bottom: 2%;
                text-transform: capitalize;
            }

            p {
                font-size: 1.3vw;
                line-height: 180%;
            }

            ul {
                li {
                    font-size: 1.3vw;
                    line-height: 180%;
                }
            }

            button {
                background: transparent;
                color: #fff;
                border-radius: 10px;
                border: 2px solid #fff;
                padding: 1% 5%;
                text-align: center;

                &:hover {
                    background: $gradient;
                    border: 2px solid transparent;
                    transition: $transition;
                }
            }
        }



        .b-p-brand {
            padding-top: 5%;
            @include flex;
            flex-wrap: wrap;
            animation: slide-in-down 1s forwards;
            transition: $transition;

            h6 {
                border-radius: 35px 0 35px 0;
                font-size: 1.2vw;
                text-align: center;
                background: $gradient;
                box-shadow: $box-shadow;
                width: calc(100% / 5.6);
                margin: 1%;
                padding: 0;
                color: #fff;
                padding: 2%;

            }
        }

    }

    .cardiovascular {
        background-image: url("https://res.cloudinary.com/ddwk3hbiu/image/upload/v1699518888/Akcent%20HealthCare/Brand%20Portfolio/cardiovascular_dkzklx.png");

    }

    .neurology {
        background-image: url("https://res.cloudinary.com/ddwk3hbiu/image/upload/v1699018209/Akcent%20HealthCare/Brand%20Portfolio/Neurology_askwz6.png");
    }

    .orthopedics {
        background-image: url("https://res.cloudinary.com/ddwk3hbiu/image/upload/v1699018211/Akcent%20HealthCare/Brand%20Portfolio/Orthopedics_lrbu3u.png");
    }

    .oncology {
        background-image: url("https://res.cloudinary.com/ddwk3hbiu/image/upload/v1699018210/Akcent%20HealthCare/Brand%20Portfolio/Oncology_ahf8kr.png");
    }

    .surgery {
        background-image: url("https://res.cloudinary.com/ddwk3hbiu/image/upload/v1699018213/Akcent%20HealthCare/Brand%20Portfolio/Surgery_dhhok1.png");
    }

    .gynecology {
        background-image: url("https://res.cloudinary.com/ddwk3hbiu/image/upload/v1699018210/Akcent%20HealthCare/Brand%20Portfolio/Gynecology_ftqyiu.png");
    }

    .ent {
        background-image: url("https://res.cloudinary.com/ddwk3hbiu/image/upload/v1699018208/Akcent%20HealthCare/Brand%20Portfolio/ENT_mt59eb.png");
    }

    .nephrology {
        background-image: url("https://res.cloudinary.com/ddwk3hbiu/image/upload/v1699018208/Akcent%20HealthCare/Brand%20Portfolio/Nephrology_mn8v2s.png");
    }

    .urology {
        background-image: url("https://res.cloudinary.com/ddwk3hbiu/image/upload/v1699018214/Akcent%20HealthCare/Brand%20Portfolio/Urology_t45kzt.png");
    }

    .physician {
        background-image: url("https://res.cloudinary.com/ddwk3hbiu/image/upload/v1699018215/Akcent%20HealthCare/Brand%20Portfolio/Physician_ahpr1e.png");
    }

    .gastroenterology {
        background-image: url("https://res.cloudinary.com/ddwk3hbiu/image/upload/v1699018208/Akcent%20HealthCare/Brand%20Portfolio/Gastroenterology_colora.png");
    }

    .presence {
        padding: $padding;

        .presence-body {
            @include flex;
            flex-wrap: wrap;

            h6 {
                font-size: 1.3vw;
                width: calc(100% / 3.2);
                padding: 2%;
                box-shadow: $box-shadow;
                margin: 1%;
                text-align: center;
            }
        }
    }
}


/*===============================================================
# Gallery
===============================================================*/
.gallery-buttons {
    width: 80%;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5% 0;

    button {
        font-size: 1.3vw;
        color: #fff;
        background: $gradient;
        border-radius: 10px;
        margin-right: 5%;
        padding: 1% 3%;
        border: 1px solid #fff;
        text-transform: capitalize;

        &:hover {
            border: 1px solid $orange;
            transition: $transition;
            background: transparent;
            color: #000;
        }
    }
}

.album .responsive-container-block {
    min-height: 75px;
    height: fit-content;
    width: 100%;
    padding-top: 10px;
    padding-right: 10px;
    padding-bottom: 10px;
    padding-left: 10px;
    display: flex;
    flex-wrap: wrap;
    margin-top: 0px;
    margin-right: auto;
    margin-bottom: 0px;
    margin-left: auto;
    justify-content: flex-start;
}

.album .responsive-container-block.bg {
    max-width: 1320px;
    margin: 0 0 0 0;
    justify-content: space-between;
}

.album .img {
    width: 100%;
    margin: 0 0 20px 0;
}

.album #i9rb {
    color: black;
}

.album #ir6i {
    color: black;
}

.album #ikz3b {
    color: black;
}

.album .responsive-container-block.img-cont {
    flex-direction: column;
    max-width: 33.3%;
    min-height: auto;
    margin: 0 0 0 0;
    height: 100%;
}

.album #ipix {
    color: black;
}

.album #ipzoh {
    color: black;
}

.album #ig5q8 {
    color: black;
}

.album #imtzl {
    color: black;
}

.album #i53es {
    color: black;
}

.album .img.img-big {
    height: 50%;
    margin: 0 0 16px 0;
}

@media (max-width: 1024px) {
    .album .img {
        margin: 0 0 18px 0;
    }
}

@media (max-width: 768px) {
    .album .img {
        max-width: 32.5%;
        margin: 0 0 0 0;
    }

    .album .responsive-container-block.bg {
        flex-direction: column;
    }

    .album .responsive-container-block.img-cont {
        max-width: 100%;
        flex-direction: row;
        justify-content: space-between;
    }

    .album .img.img-big {
        max-width: 49%;
        margin: 0 0 0 0;
    }
}

@media (max-width: 500px) {
    .album .img {
        max-width: 94%;
        margin: 0 0 25px 0;
    }

    .album .responsive-container-block.img-cont {
        flex-direction: column;
        align-items: center;
        padding: 10px 10px 10px 10px;
    }

    .album .img.img-big {
        max-width: 94%;
        margin: 0 0 25px 0;
    }

    .album .img.img-last {
        margin: 0 0 5px 0;
    }
}

/*===============================================================
# Life At Akcent
===============================================================*/
.life-at-akcent {

    .life-employee {
        padding: $padding;
        margin-top: -3%;
        padding-top: 5%;

        .life-employee-body {

            .carousel.carousel-slider .control-arrow {
                height: 100%;

                &:hover {
                    background: transparent;
                }
            }

            .carousel .control-prev.control-arrow:before {
                border-right: 8px solid $orange;
            }

            .carousel .control-next.control-arrow:before {
                border-left: 8px solid $orange;
            }

            .carousel .control-dots .dot {
                box-shadow: 1px 1px 5px $orange;
                border: 2px solid $orange;
                width: 15px;
                height: 15px;
            }

            .carousel {
                .carousel-wrapper {

                    .carousel-body {
                        box-shadow: $box-shadow;
                        width: 80%;
                        padding: 3%;
                        margin: 5% auto;
                        height: 70%;
                        border-left: 2px solid $yellow;
                        border-right: 2px solid $yellow;
                        background: #fff;
                        border-top: none;
                        position: relative;

                        svg {
                            width: 100px;
                            height: 100px;
                            position: absolute;
                            top: -22%;
                            fill: $yellow;
                            left: 5%;
                        }

                        h3 {
                            font-size: 2vw;
                            color: $orange;
                            // color: #fff;
                        }

                        h6 {
                            font-size: 1.1vw;
                            color: $grey;
                            margin: 2% 0;
                            // color: #fff;
                        }

                        p {
                            font-size: 1vw;
                            color: rgba($color: #000, $alpha: 0.8);
                            // color: #fff;

                        }
                    }
                }
            }
        }
    }

    .life-events {
        padding: $padding;

        .life-events-body {}
    }

    .life-gallery {
        padding: $padding;

        .life-gallery-body {
            @include flex;
            flex-wrap: wrap;

            img {
                width: calc(100% / 3.2);
                margin: 1%;
                box-shadow: $box-shadow;
                object-fit: cover;
            }
        }
    }
}


/*===============================================================
# Career
===============================================================*/
.career {

    .career-title {
        background: $transparent-gradient;
        padding: 5%;
        margin: 5% 0;

        h2 {
            color: #fff;
            font-size: 3vw;
            margin-bottom: 0;
        }
    }

    .career-body {
        padding: $padding;
        @include flex;
        flex-wrap: wrap;

        .career-card {
            padding: 2%;
            box-shadow: $box-shadow;
            width: calc(100% / 3.1);
            margin: .5%;

            h3 {
                font-size: 2vw;
                color: $orange;
                font-weight: bold;
            }

            p {
                font-size: 1.2vw;
                font-style: italic;
                margin-bottom: 0;
            }

            .career-btn {
                @include flex;

                button {
                    width: calc(100% / 2);
                    border: 2px solid $orange;
                    border-radius: 5px;
                    background: transparent;
                    padding: 3% 5%;
                    font-size: 1vw;
                    margin-top: 10%;
                    margin-right: 5%;
                }

            }


        }
    }

    .career-why {
        padding: $padding;

        .career-why-body {
            @include flex;
            flex-wrap: wrap;

            .career-why-card {
                width: calc(100% / 3.2);
                box-shadow: $box-shadow;
                padding: 2%;
                margin: 1%;

                .svg {
                    width: 70px;
                    height: 70px;
                    position: relative;
                    box-shadow: $box-shadow;
                    border-radius: 50%;
                    margin-bottom: 5%;

                    svg {
                        width: 40px;
                        height: 40px;
                        @include center-position;
                        fill: $orange;
                    }
                }


                h4 {
                    font-size: 1.5vw;
                    font-weight: bold;
                    text-transform: capitalize;
                }

                p {
                    font-size: 1vw;
                    color: $grey;
                }
            }
        }
    }
}

.carrer-form {
    .modal-dialog {
        z-index: 300000 !important;
    }

    .modal-header,
    .modal-footer {
        background: $gradient;
        color: #fff;
    }

    .modal-body {
        background: $gradient;
    }

    form {
        input {
            // background: transparent;

            &::placeholder {
                text-transform: capitalize;
            }

            &:focus {
                outline: none;
            }
        }

        button {
            background: transparent;
            color: #fff;
            border: 3px solid #fff;

            &:hover {
                color: #000;
                border: 3px solid #fff;
                background: #fff;

            }
        }
    }

}

/*===============================================================
# Blog
===============================================================*/
.blog {
    padding: $padding;
    margin-top: 5%;

    .blog-body {
        .blog-body-content {
            @include flex;
            flex-wrap: wrap;

            .blog-card {
                position: relative;
                margin-bottom: 3%;
                width: calc(100% / 3.2);
                margin: 1%;

                img {
                    width: 100%;
                    border: 1px solid $orange;

                }

                .blog-card-text {
                    @include flex;
                    flex-direction: column;

                    .date {
                        position: absolute;
                        bottom: 18%;
                        right: 5%;
                        background: #fff;
                        padding: 1% 3%;
                        border-radius: 10px 10px 0 0;
                        font-size: 1.5vw;
                        font-weight: bold;
                    }

                    a {
                        font-size: 2vw;
                        color: $orange;
                        margin-top: 2%;
                    }
                }

            }
        }

        .blog-body-text {
            width: 30%;
            padding-left: 5%;

            .blog-body-categories {

                &:last-child {
                    h4 {
                        padding-top: 15%;
                    }
                }

                h4 {
                    font-size: 2vw;
                    color: rgba($color: #000, $alpha: 0.8);
                    border-bottom: 2px solid $orange;
                    padding-bottom: 3%;
                    margin: 0;
                }

                p {
                    &:hover {
                        transition: .5s all ease;
                        padding-left: 7%;
                        color: $orange;
                    }
                }

                .latest {
                    display: flex;
                    flex-direction: column;

                    a {
                        border-bottom: 1px solid rgba($color: #000, $alpha: 0.2);
                        padding: 5% 0;

                        &:hover {
                            transition: .5s all ease;
                            padding-left: 7%;
                            transition: .5s all ease;
                            color: $orange;

                        }
                    }


                }
            }
        }

    }
}

.blog-details {
    margin-top: 15%;
    margin-left: auto;
    margin-right: auto;
    width: 80%;

    .blog-body-content {
        .blog-card {
            width: 100% !important;
        }
    }

    .blog-card-text {
        h1 {
            font-size: 2.5vw;
            margin: 5% 0;
            text-align: center;
            text-transform: capitalize;
            color: $orange;
            line-height: 150%;
        }

        .date {
            bottom: 30% !important;
            font-size: 2vw !important;
        }

        .body {
            h3 {
                font-weight: bold;
                font-family: 'Signika', sans-serif;
                margin-top: 3%;
            }

            p {
                color: rgba($color: #000, $alpha: 0.7);
                letter-spacing: .3px;
                line-height: 200%;
                font-size: 1.3vw;

                strong {
                    font-size: 1.5vw;
                    color: #000;
                    letter-spacing: .3px;
                }
            }

            ol,
            ul {
                padding-left: 10%;

                li {
                    list-style-type: unset;
                    margin: 1% 0;
                    color: rgba($color: #000, $alpha: 0.7);
                    letter-spacing: .3px;
                    font-size: 1.3vw;

                    strong {
                        color: #000;
                    }

                }
            }

            a {
                color: $orange;
                text-decoration: none;
                font-style: italic;
                font-size: 1.3vw;
            }
        }


    }
}

// .bcat {
//     cursor: pointer;

//     p {
//         margin: 0;
//         border-bottom: 1px solid rgba($color: #000, $alpha: 0.2);
//         padding: 5% 0;
//     }
// }

/*===============================================================
# Contact
===============================================================*/
.contact {
    padding: $padding;
    margin-top: 5%;

    .contact-info {
        @include flex;

        .contact-info-card {
            width: calc(100% / 3);
            margin: 1%;
            border-radius: 10px;
            box-shadow: $box-shadow;
            padding: 2%;
            display: flex;
            flex-direction: column;

            &:hover {
                background: $gradient;
                transition: $transition;

                svg {
                    transition: $transition;

                    fill: #fff;
                }

                a {
                    transition: $transition;

                    color: #fff;
                }
            }

            svg {
                width: 80px;
                height: 80px;
                fill: $orange;
                transform: translate(150%, -30%);
                margin-top: 5%;
            }

            a {
                text-align: center;
                // font-weight: bold;
                font-size: 1.3vw;
                color: $orange;
            }
        }
    }

    .contact-form {
        @include flex;
        justify-content: space-between;
        margin-top: 5%;
        box-shadow: $box-shadow;

        img {
            width: 60%;
            object-fit: cover;
            padding-right: 5%;
        }

        form {
            width: 40%;
            margin-left: -30%;
            display: flex;
            flex-direction: column;
            // background: #fff;
            // border-radius: 300px 0 0 300px;
            padding: 0 5%;
            margin: 5% 0;
            border-left: 1px solid rgba($color: $orange, $alpha: 0.2);


            h2 {
                color: $orange;
                font-size: 2.5vw;
                text-align: center;
                text-transform: capitalize;
                margin-bottom: 5%;
            }

            input,
            textarea {
                background: transparent;
                border: none;
                border-bottom: 1px solid $yellow;
                padding: 2% 3%;
                margin: 1% 0;
                outline: none;
                color: $grey;
                font-size: 1.1vw;

                &::placeholder {
                    color: $grey;
                }
            }

            button {
                border: 1px solid $yellow;
                background: transparent;
                padding: 1% 3%;
                margin-top: 4%;
                border-radius: 5px;


                &:hover {
                    background: $transparent-gradient;
                    color: #fff;
                    transition: $transition;
                }
            }
        }
    }

    .contact-map {
        margin-top: 5%;

        iframe {
            width: 100%;
            height: 70vh;
            display: block;
        }
    }
}

/* ====================================================================
    Privacy Policy
=====================================================================*/
.privacy-policy {
    padding: 5%;

    ul {
        padding-left: 5%;

        li {
            list-style-type: circle;
        }
    }

    h3 {
        margin-top: 3%;
        font-size: 2vw;
        font-weight: bold;
    }

    h4 {
        font-size: 1.5vw;
        margin-top: 3%;

    }

    p {
        font-size: 1.3vw;
    }

}


/*===============================================================
# Footer
===============================================================*/
footer {
    padding: 2% 2% 0 2%;
    background: $transparent-gradient;

    .footer-body {
        @include flex;
        color: #000;

        .footer-about,
        .footer-links,
        .footer-contact,
        .footer-map {
            margin: 1%;

        }

        .footer-about {
            width: 30%;

            h2 {
                font-size: 1.3vw;
                text-transform: uppercase;
                font-weight: bold;
                letter-spacing: 1px;
                margin-bottom: 5%;
            }

            p {
                font-size: 1vw;
                color: rgba($color: #000, $alpha: 0.8);
                text-align: justify;
            }
        }

        .footer-links {
            width: 20%;
            padding-left: 3%;

            h4 {
                font-size: 1.3vw;
                text-transform: uppercase;
                font-weight: bold;
                margin-bottom: 10%;
            }

            .footer-links-content {
                display: flex;
                flex-direction: column;

                a {
                    font-size: 1.1vw;
                    text-transform: capitalize;
                    margin-bottom: 3%;

                    &:hover {
                        color: #fff;
                        transform: scale(1.1) translatex(15px);
                        transition: .5s all ease;

                        svg {
                            fill: #fff;
                        }
                    }

                    svg {
                        width: 12px;
                        height: 12px;
                        fill: #000;
                    }
                }
            }
        }

        .footer-contact {
            width: 30%;



            h4 {
                font-size: 1.3vw;
                text-transform: capitalize;
                margin-bottom: 8%;
                text-transform: uppercase;
                font-weight: bold;
            }

            .footer-contact-body {

                .footer-contact-content {
                    display: flex;
                    align-items: center;
                    margin-bottom: 2%;

                    svg {
                        margin-right: 5%;
                        width: 15px;
                        height: 15px;
                        fill: #000;
                    }

                    a {
                        font-size: 1.1vw;
                        text-transform: capitalize;
                    }

                    &:hover {
                        a {
                            color: #fff;
                            transition: $transition;
                        }

                        svg {
                            fill: #fff;
                            transition: $transition;
                        }
                    }
                }

            }

            .footer-follow {
                margin: 10% 0;
                display: flex;
                justify-content: flex-start;
                align-items: center;

                a {
                    margin-right: 3%;
                    border: 1px solid #000;
                    padding: 2%;
                    border-radius: 5px;
                    width: 30px;
                    height: 30px;
                    position: relative;

                    svg {
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        width: 20px;
                        height: 20px;
                        fill: #000;
                    }

                    &:hover {
                        background: #fff;
                        transition: .2s all ease;
                        border: 1px solid transparent;
                        transform: scale(1.2);

                        svg {
                            fill: $yellow;
                        }
                    }
                }

            }
        }

        .footer-map {
            width: 30%;

            iframe {
                width: 100%;
                height: 100%;
                border: none;
            }
        }
    }

    .footer-copyright {
        @include flex;
        border-top: 1px solid rgba($color: #fff, $alpha: 0.3);
        color: #000;
        justify-content: space-between;
        align-items: center;
        padding: 2% 0;

        p {
            font-size: 1.1vw;
            margin-left: 5%;

            a {
                font-weight: bold;

                &:hover {
                    color: #fff;
                    transition: $transition;
                }
            }
        }

        .footer-copyright-content {
            width: 30%;
            color: #000;

            &:hover {
                color: #fff;
                transition: $transition;
            }

            a {
                font-size: 1.1vw;
                margin-right: 5%;
            }
        }
    }
}